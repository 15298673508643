import React, { useState, useEffect } from 'react';
import { Tabs } from 'antd';
import { Wrapper } from '../../../../../components/content';
import { Select } from 'antd';
import { useProducts } from '../../../../../context/products';

const { Option } = Select;

const InnerTab = ({ item, setAttributesWithUnits, attributesWithUnits, noAttrWithUnits, setNoAttrWithUnits }) => {
  const [defaultValueForUnits, setDefaultValueForUnits] = useState([]);

  useEffect(() => {
    if (Array.isArray(attributesWithUnits) && attributesWithUnits.length > 0) {
      const test = attributesWithUnits?.find((attr) => Array.isArray(attr?.Attribute) && attr.Attribute[0] === item?._id)?.values;

      setDefaultValueForUnits(test);
    }
  }, [item]);

  const { setForm } = useProducts();
  const units = item.units;

  const handleOnChangeUnits = (event) => {
    const value = event;
    setDefaultValueForUnits(value);

    const selectedObjects = units.filter((unit) => value.includes(unit._id));

    if (noAttrWithUnits?.length === 0) {
      setAttributesWithUnits((prevState) => {
        // return [
        //   // ...prevState,
        //   {
        //     Attribute: [item._id],
        //     values: selectedObjects.map((obj) => obj._id),
        //   },
        // ];
        return prevState.map((prevItem) => {
          if (prevItem.Attribute[0] === item._id) {
            return { ...prevItem, values: selectedObjects.map((obj) => obj._id) };
          }
          return prevItem;
        });
      });
      setNoAttrWithUnits(['noAttrWithUnits']);
      // setAttributesWithUnits([
      //   {
      //     Attribute: [item._id],
      //     values: selectedObjects.map((obj) => obj._id),
      //   },
      // ]);
      return;
    }
    setAttributesWithUnits((prevState) => {
      return prevState.map((item) => {
        if (item && item.Attribute && item?.Attribute[0] && selectedObjects?.map((obj) => obj.attribute).includes(item.Attribute[0])) {
          return { ...item, values: selectedObjects.map((obj) => obj._id) };
        }
        return item;
      });
    });
  };

  const handleDeselect = (value) => {
    setAttributesWithUnits((prevState) => {
      return prevState.map((item) => {
        if (item.values.includes(value)) {
          return { ...item, values: item.values.filter((val) => val !== value) };
        }
        return item;
      });
    });
  };

  return (
    <div className="form-group form-group-selection-items">
      <Select mode="multiple" value={defaultValueForUnits} onChange={handleOnChangeUnits} onDeselect={handleDeselect} showArrow name="updateSubcategoryAttributes" placeholder="Izaberite vrednosti atributa" type="text" id="updateSubcategoryAttributes" style={{ width: '100%' }}>
        {units &&
          units.length > 0 &&
          units.map((item) => {
            return (
              <Option key={item._id} value={item._id}>
                {item.value}
              </Option>
            );
          })}
      </Select>
    </div>
  );
};

export const ProductAttributesUpdate = ({ attributesOptions, selectedSubCategory }) => {
  const { form, setForm } = useProducts();
  const [noAttrWithUnits, setNoAttrWithUnits] = useState(form?.productAttributesUnit || []);
  const [attributesWithUnits, setAttributesWithUnits] = useState(
    form?.productAttributesUnit?.map((item) => {
      return { Attribute: item?.Attribute, values: item?.values || [] };
    })
  );

  useEffect(() => {
    setAttributesWithUnits();
    // form?.productAttributesUnit?.map((item) => {
    //   return { Attribute: item?.Attribute, values: item?.values || [] };
    // })
  }, [selectedSubCategory, attributesOptions]);

  useEffect(() => {
    const test = attributesOptions?.filter((attr) => {
      return !attributesWithUnits?.map((item) => item && item.Attribute && item?.Attribute[0])?.includes(attr._id);
    });

    if (test.length === 0) {
      setAttributesWithUnits(
        form?.productAttributesUnit?.map((item) => {
          return { Attribute: item?.Attribute, values: item?.values || [] };
        })
      );
    } else if (test.length > 0) {
      setAttributesWithUnits([
        ...form?.productAttributesUnit?.map((item) => {
          return { Attribute: item?.Attribute, values: item?.values || [] };
        }),
        ...test?.map((optionsec) => {
          return { Attribute: [optionsec._id], values: [] };
        }),
      ]);
    }
  }, [selectedSubCategory, attributesOptions]);

  useEffect(() => {
    setForm((prevState) => {
      return { ...prevState, productAttributesUnit: attributesWithUnits };
    });
  }, [attributesWithUnits]);

  return (
    <Wrapper className={`product__card product__card--tags`}>
      <header className="card-header">
        <h4>Atributi Proizvoda</h4>
      </header>
      <main className="card-content">
        {attributesOptions && attributesOptions.length > 0 && (
          <div className="form-group form-group-selection-items">
            <Tabs
              defaultActiveKey="1"
              type="card"
              size="large"
              items={attributesOptions.map((item, i) => {
                return {
                  label: `${item.name}`,
                  key: i,
                  children: <InnerTab setNoAttrWithUnits={setNoAttrWithUnits} item={item} noAttrWithUnits={noAttrWithUnits} attributesOptions={attributesOptions} attributesWithUnits={attributesWithUnits} setAttributesWithUnits={setAttributesWithUnits} />,
                };
              })}
            />
          </div>
        )}
        {selectedSubCategory && !attributesOptions.length && <p>Nema atributa za izabranu podkategoriju.</p>}
        {!selectedSubCategory && !attributesOptions.length && <p>Izaberite podkategoriju proizvoda.</p>}
      </main>
    </Wrapper>
  );
};
